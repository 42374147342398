<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title"></BaseBreadcrumb>

    <v-row class="ml-2" v-if="documentName != null && documentOrigin != null">
      <span>
        Se ha recibido el documento
      </span>
      <span class="font-weight-black ml-1">
        {{ documentName }}
      </span>
      <span class="ml-1">
        desde
      </span>
      <span class="ml-1 font-weight-black">
        {{ documentOrigin }}
      </span>
    </v-row>

    <v-spacer></v-spacer>

    <v-row class="mt-4">
      <v-col cols="12" md="6">
        <v-card
          class="actionable-card"
          @click="onClickRemote"
          elevation="3"
          :loading="isLoading"
          :disabled="isLoading"
        >
          <v-card-text class="pa-5">
            <div class="d-flex align-center justify-center">
              <v-btn :class="'primary'" class="elevation-0" fab dark>
                <v-icon>mdi-email</v-icon>
              </v-btn>
              <div class="ml-3 mr-1">
                <h3 class="">
                  Enviar a firmar
                </h3>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card
          class="actionable-card"
          @click="onClickFaceToFace"
          elevation="3"
          :loading="isLoading"
          :disabled="isLoading"
        >
          <v-card-text class="pa-5">
            <div class="d-flex align-center justify-center">
              <v-btn :class="'primary'" class="elevation-0" fab dark>
                <v-icon>mdi-signature</v-icon>
              </v-btn>
              <div class="ml-3 mr-1">
                <h3 class="">
                  Firma presencial
                </h3>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "DocumentReceiver",

  data: () => ({
    page: {
      title: "Receptor de documentos de Firmar.Online"
    },
    documentName: null,
    documentOrigin: null,
    isLoading: true,
    file: null,
    reference: null,
    docId: null
  }),

  mounted() {
    this.addEventListener();
    this.postMessage();
  },

  destroyed() {
    window.removeEventListener("message", this.receiveMessage);
  },

  methods: {
    onClickRemote() {
      this.$router.replace({
        name: "RemoteSignaturesSent",
        params: {
          file: this.file,
          reference: this.reference,
          docId: this.docId
        }
      });
    },
    onClickFaceToFace() {
      this.$router.replace({
        name: "FaceToFaceSignatures",
        params: {
          file: this.file,
          reference: this.reference,
          docId: this.docId
        }
      });
    },
    postMessage() {
      if (window.opener) {
        window.opener.postMessage({ eventName: "WebAppReady" }, "*");
      } else {
        this.$router.replace({
          name: "Dashboard"
        });
      }
    },
    addEventListener() {
      window.addEventListener("message", this.receiveMessage, false);
    },
    receiveMessage(event) {
      if (event.data.data && event.data.origin) {
        try {
          if (
            event.data.documentName != null &&
            event.data.documentName != ""
          ) {
            this.documentName = event.data.documentName;
          } else {
            this.documentName = "Documento de " + event.data.origin;
          }

          this.documentOrigin = event.data.origin;
          this.file = new File(
            [byteToUint8Array(event.data.data)],
            this.documentName,
            { type: "application/pdf" }
          );
          event.data.reference ? (this.reference = event.data.reference) : null;
          event.data.docId ? (this.docId = event.data.docId) : null;
          console.log(this.reference);
          this.getComplementSettings();
        } catch (e) {
          console.log(e);
        }
      }
    },
    getComplementSettings() {
      this.isLoading = true;
      this.$store
        .dispatch("getPrinterConfiguration")
        .then(() => {
          if (this.$store.state.printerConfiguration.requestType) {
            if (this.$store.state.printerConfiguration.requestType == 0) {
              this.isLoading = false;
            } else if (
              this.$store.state.printerConfiguration.requestType == 1
            ) {
              this.onClickRemote();
            } else if (
              this.$store.state.printerConfiguration.requestType == 2
            ) {
              this.onClickFaceToFace();
            } else {
              this.isLoading = false;
            }
          } else {
            this.isLoading = false;
          }
        })
        .catch(err => {
          this.isLoading = false;
          console.log(err);
        });
    }
  }
};

function byteToUint8Array(byteArray) {
  var uint8Array = new Uint8Array(byteArray.length);
  for (var i = 0; i < uint8Array.length; i++) {
    uint8Array[i] = byteArray[i];
  }
  return uint8Array;
}
</script>
